
<div class="ps-2 vue_clima d-flex align-items-center">
  <div :class="{'d-none': !isMobile, 'mobile-clima': isMobile}" class="d-md-flex align-items-center mx-2 header-text-color" v-tooltip="{
    content: fullTime,
    boundariesElement: 'window'
  }">
    <img :src="timeIcon" class="me-2 img-fluid icon"></img> {{time}}
  </div>

  <div v-if="status.clima"  class="spinner-border spinner-border-sm mr-3 header-text-color" role="status">
    <span class="sr-only">Loading...</span>
  </div>

  <div :class="{'d-none': !isMobile, 'mobile-clima': isMobile}" class="d-md-flex align-items-center mx-2 header-text-color" v-else-if="clima && climaIcon" v-tooltip="{
    content: weatherTooltipText,
    boundariesElement: 'window'
  }">
    <img :src="climaIcon" class="me-2 img-fluid icon"></img> {{weatherText}}
  </div>
</div>
