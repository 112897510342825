<template>
  <div class="ps-2 vue_clima d-flex align-items-center">
    <div :class="{'d-none': !isMobile, 'mobile-clima': isMobile}" class="d-md-flex align-items-center mx-2 header-text-color" v-tooltip="{
      content: fullTime,
      boundariesElement: 'window'
    }">
      <img :src="timeIcon" class="me-2 img-fluid icon"></img> {{time}}
    </div>

    <div v-if="status.clima"  class="spinner-border spinner-border-sm mr-3 header-text-color" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <div :class="{'d-none': !isMobile, 'mobile-clima': isMobile}" class="d-md-flex align-items-center mx-2 header-text-color" v-else-if="clima && climaIcon" v-tooltip="{
      content: weatherTooltipText,
      boundariesElement: 'window'
    }">
      <img :src="climaIcon" class="me-2 img-fluid icon"></img> {{weatherText}}
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    locale: String,
    isMobile: Boolean
  },
  data: () => ({
    time: null,
    fullTime: null,
    clima: null,
    climaIcon: null,
    status: {
      clima: false
    },
    weatherTooltipText: null
  }),
  mounted() {
    this.configureMoment();
    this.getTime();
    this.getClima();
  },
  computed: {
    weatherText() {
      return this.locale == 'es' ? `${parseInt(this.clima)}°C` : `${parseInt(this.clima * 1.8000 + 32.00)}°F`;
    },
    timeIcon() {
      return 'https://asur.mints.cloud' + `/public-assets/time-icon${this.isMobile ? '-mobile' : ''}`;
    }
  },
  methods: {
    getClima() {
      this.status.clima = true;

      this.$axios.get(`/api/v1/geolocation?lang=${this.locale}`).then(payload => {
        const data = payload.data.data;

        const { main, name, sys, weather } = data;
        this.clima = main.temp;
        if (weather && weather[0]) {
          const icon = weather[0]["icon"] ? weather[0]["icon"].replace('n', 'd') : null
          if (icon) this.climaIcon = 'https://asur.mints.cloud' + '/public-assets/' + icon + (this.isMobile ? '-mobile' : '');
        }

        this.weatherTooltipText = `${name}, ${this.weatherText}, ${weather[0].description}`
      }).finally(() => {
        this.status.clima = false;
      });
    },
    getTime() {
      let cancunTime = moment().utcOffset('-0500');

      this.fullTime = cancunTime.locale(this.locale).format('LLLL');
      this.time = cancunTime.subtract(1,'days').format('h:mm a');
      let seconds = parseInt(moment().subtract(1,'days').format('ss'));
      setTimeout(() => {
        this.getTime();
      }, (60 - seconds))
    },
    configureMoment() {
      if (this.locale == 'es') {
        moment.updateLocale('es', {
            months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
            monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
            weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
            weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
            weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
          }
        );

        moment.locale('es');
      }
    }
  }
}
</script>