import Vue from 'vue'
import App from '../components/clima/App.vue'
import VTooltip from 'v-tooltip'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(VTooltip, { defaultDelay: { show: 500, hide: 100 } });

Vue.prototype.$locale = locale ? locale : 'es';
Vue.prototype.$contact = (typeof contact != 'undefined') ? contact : null;

const containers = document.querySelectorAll(`div[id^="vue-clima-"]`);

containers.forEach((container) => {
    document.addEventListener('DOMContentLoaded', () => {
        const app = new Vue({
            render: h => h(App, { props: { locale: locale, isMobile: container.id.includes('2') } })
        }).$mount(`#${container.id}`)
    })
});